@import "../variables";

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      font-weight: bold;
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      margin: 0;
      margin-right: 20px;
      padding: 10px 23px 10px 0;
      font-size: 24px;
      font-weight: 500;
      vertical-align: top;
    }
    h2 {
      font-size: 14px;
      font-weight: normal;
      line-height: inherit;
      margin: 0;
      padding: 0;
    }
  }

  svg {
    margin-top: 2rem;
    display: none;
  }
  .duck {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .image {
      height: 250px;
      width: 300px;
      background: url("/images/duck.png") no-repeat center;
      background-size: contain;
    }
  }
}
